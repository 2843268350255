import React from "react";
import {graphql, useStaticQuery} from 'gatsby';
import Blog from '../layouts/Blog/';
import Layout from "../components/layout";
import Seo from "../components/seo";
import HomeContentSection from '../layouts/Home/HomeContentSection';

const BlogPageFr = () => {
  const data = useStaticQuery(graphql`
    query BlogPageFr {
      allContentfulArticleDeBlogue(filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            title
            createdAt(locale: "fr", formatString: "Do MMMM YYYY")
            excerpt
            slug
            postTypes {
              name
            }
            headerImg {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulTypeDarticle(sort: {fields: name, order: ASC}, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
          }
        }
      }
    }
  `)
  return (
    <Layout 
    lang='fr-CA' 
    switchLink='/en/blog/'
    slider={false} 
    title='Blogue'>
      <Seo 
        title='Maîtrisez votre affichage dynamique'
        description={'Consultez des nouvelles et des trucs pour maximiser l\'utilisation des outils d\'affichage dynamique, de l\'écran à la création de contenus.'}
       />
       <Blog lang="fr-CA" posts={data.allContentfulArticleDeBlogue.edges} postTypes={data.allContentfulTypeDarticle.edges}/>
       <HomeContentSection/>
    </Layout>
  )
}

export default BlogPageFr